import React from 'react'

interface WorkBoxProps {
    title: string;
    languages: string;
    company: string;
    duration: string;
    imagePath: string;
    description: string;
}
const WorkBox: React.FC<WorkBoxProps> = ({ title, languages, company, duration, imagePath, description }) => {
    return(
       <div className="grid grid-cols-1 md:grid-cols-4 p-2 md:flex-row gap-12">
            {/* <img 
                src={imageFiller}
                alt="3D Model"
                className="rounded-md"
                /> 
            <div className="flex flex-col p-4 gap-4">
                <h3>Project Title</h3>
                <h3 className="text-start">Languages/Skills Used</h3>
            </div>    */}
            <div>
                <img 
                    src={imagePath} //just put images in the image folder in the public folder and use the 
                    alt="3D Model"
                    className="rounded-lg justifty-center" //hover:scale-105
                    
                />
            </div>
            
            <div className="col-span-1 md:col-span-3 grid grid-cols-1 md:grid-cols-3 text-start gap-6">
                <div className="col-span-1">
                    <h1 className="font-bold text-2xl">{title}</h1>
                    <p className="text-lg italics">{company}</p>
                    <p className="text-lg italics">{duration}</p>
        
                </div>

                <div className="col-spand-2 md:col-span-2 text-base">
                    <p>{description}</p>
                </div>
            </div>
            
       </div> 
        
    )
}

export default WorkBox