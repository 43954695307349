import React from 'react'

interface ProjectBoxProps {
    title: string;
    description: string;
    imagePath: string;
    link: string;
}
const ProjectBox: React.FC<ProjectBoxProps> = ({ title, description, imagePath, link }) => {
    return(
       <div className="grid rounded-lg m-2 p-2 gap-6">
            {/* <img 
                src={imageFiller}
                alt="3D Model"
                className="rounded-md"
                /> 
            <div className="flex flex-col p-4 gap-4">
                <h3>Project Title</h3>
                <h3 className="text-start">Languages/Skills Used</h3>
            </div>    */}
           <a href={link}>
            <img 
                src={imagePath} //just put images in the image folder in the public folder and use the 
                alt="3D Model"
                className="rounded-lg hover:scale-105" //hover:scale-105
            />
            </a>
            <div className="grid grid-cols-1 gap-6 text-start">
                <h1 className="font-bold text-3xl">{title}</h1>
                <h2 className="text-base italics">{description}</h2>
                
            </div>
       </div> 
        
    )
}

export default ProjectBox