import React from 'react'

interface LanguagePillProps {
    language: string;
}
const LanguagePill: React.FC<LanguagePillProps> = ({ language }) => {
    return(
       <div className="rounded-full bg-gradient-to-r from-slate-300 via-slate-100 to-slate-300 p-2 w-min text-black">
            <h2 className="text-sm italic">{language}</h2>
       </div> 
        
    )
}

export default LanguagePill