
import './App.css';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
// Component Imports
import NavBar from './components/NavBar';
import Introduction from './components/Introduction';
import Projects from './components/Projects';
import Work from './components/Work';
// import Art from './components/Art';
import Landing from './components/Landing';
function App() {
  return (
    <>
    <div className="App bg-gradient-to-r from-slate-800 via-slate-700 to-slate-800 w-full">
        <NavBar/>
        <Landing/>
        <div className=" grid gap-24">
          <Introduction/>
          <Projects/>
          <Work/>
        </div>
    </div>
  </>
  );
}

export default App;
