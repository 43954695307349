import React from 'react';
import ProjectBox from './ProjectBox';
import LanguagePill from './LanguagePill';
export default function Projects() {
    return (
        
        <div id="projects" className='grid justify-items-center text-white m-4 md:m-8 gap-0 md:gap-4'>
            <h1 className="text-5xl font-bold">./Side_Quests</h1>
            {/* <div id="projects" className="w-3/4 flex flex-col content-center md:flex-row m-16 gap-5"> */}
        <div className="grid grid-col-1 md:grid-cols-3 w-3/4 gap-6 m-3 ">

            <div className="col-span-1"> 
                <ProjectBox
                    title="Quidam"
                    description="! Currently in progess ! Enjoy a laugh with your friends as you create an AI generated NYT style article 
                    featuring the gang's names!"
                    imagePath='/images/newspaper_pixel.png'
                    link="https://github.com/maryam-elbeshbishy"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                <LanguagePill language="React"/>
                <LanguagePill language="Typescript"/>
                <LanguagePill language="Python"/>
                </div>  
            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="My Youtube"
                    description="Delve into the world of virtual reality and creativity with the MariLambb youtube channel!"
                    imagePath='/images/youtube_pixel.png'
                    link="https://www.youtube.com/@marilambb"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                    <LanguagePill language="Youtube"/>
                    <LanguagePill language="Unity"/>
                </div>
            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="As A Language Model Podcast"
                    description="Discussing my Cohere experience and explore virtual reality's limitless possibilities with Hai."
                    imagePath='/images/podcast_pixel.png'
                    link="https://www.youtube.com/watch?v=4FhmqyUEoR0"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                    <LanguagePill language="Youtube"/>
                </div>
            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="Python Draw"
                    description="Unleash your creativity with our Python app: draw, and watch the magic happen as it retrieves 
                    images based on your doodles!"
                    imagePath='/images/img_py_pixel.png'
                    link="https://github.com/maryam-elbeshbishy"
                />
                <div className="flex flex-row gap-2 justify-items-start">
                <LanguagePill language="Python"/>
                </div>
            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="BlazeBuddy"
                    description="Stay cool under fire with Blaze Buddy, your go-to web buddy for real-time local fire info!"
                    imagePath='/images/fire_pixel.png'
                    link="https://github.com/KaraHuynh/Blaze-Buddy"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                <LanguagePill language="React"/>
                <LanguagePill language="Python"/>
                </div>
            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="EduBot"
                    description="Keep track of your classes with ease! Our new bot sends you notifications about upcoming events so you never miss a beat!"
                    imagePath='/images/edubot_pixel.png'
                    link="https://github.com/maryam-elbeshbishy/ruHacks2021"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                    <LanguagePill language="Python"/>
                </div>
               

            </div>

            <div className="col-span-1"> 
                <ProjectBox
                    title="More to come"
                    description="Brewing..."
                    imagePath='/images/loading.jpg'
                    link="https://github.com/maryam-elbeshbishy"
                />
                <div className="flex flex-row flex-wrap gap-2 justify-items-start">
                    <LanguagePill language="Swift"/>
                    <LanguagePill language="Unity"/>
                    <LanguagePill language="XR"/>
                </div>
            </div>


        </div>
    </div>
    );
}