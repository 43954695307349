import React from 'react';
import WorkBox from './WorkBox';
export default function Work() {
    return (
        <div id="experience" className='grid justify-items-center text-white m-4 md:m-8 gap-4'>
            <h1 className="text-5xl font-bold">./Main_Quests</h1>
            <div id="Work" className="grid grid-cols-1 w-3/4 gap-12 m-8">
                <WorkBox
                    title="Prompt Engineer" 
                    languages="languages"
                    imagePath='/images/cohere_pixel.png'
                    company='Cohere'
                    duration='05/2023 – 08/2023'
                    description='Collaborated on an internal web application that enhances data quality with reviews and validation. 
                    Together, the team was able to contribute to enhancing the LLM (Language Model) by 5 percent through creating and reviewing high-quality code discussions. 
                    Led the effort to create a code curriculum and created a functional Slack bot to interact with a text-to-image model.'
                />
                <WorkBox
                    title="VR Consultant"
                    languages="languages"
                    imagePath='/images/vr_pixel.png'
                    company='Ontario VR Association'
                    duration='02/2023 – 06/2023'
                    description='Collaborated to enhance virtual reality (VR) experiences and configure necessary hardware and software components. 
                    The goal was to improve VR education. Consulted as an expert on setting up Unity project with Oculus headset for a startup company. 
                    Offered suggestions to optimize the VR development environment. '
                />
                <WorkBox
                    title="Web Developer"
                    languages="languages"
                    imagePath='/images/kore_pixel.png'
                    company='Kore Meta'
                    duration='02/2023 – 05/2023'
                    description='Used personally curated Figma design to build a functional website using WordPress, with a responsive layout and user-friendly navigation as well as providing feedback and aiding in the development of XR experiences.
                    Continuously refined the website’s design and functionality in response to team feedback.'
                />
                <WorkBox
                    title="Research Assistant"
                    languages="languages"
                    imagePath='/images/litrans_pixel.png'
                    company='LiTrans'
                    duration='05/2021 – 12/2021'
                    description='Created class architecture and defined object interactions by using UML to document agent attributes.
                    Developed a micro-simulation of Innisfil’s town using SUMO and NETEDIT, which simulated existing transit service, serving up to 220,000 trips with various demand levels (1.3x, 1.5x, and 2.0x).'
                />
            </div>
        </div>
    );
}