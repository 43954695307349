

export default function Introduction(){
    const resume = 'https://drive.google.com/file/d/1PHdyv_firhwJLVtqe4Bzj6gsp2SFVpzg/view?usp=sharing'
    const profile = '/images/pfp.png'

    return(
        <div className='grid justify-items-center'>
            <div id="about" className="grid grid-cols-1 md:grid-cols-3 justify-items-center w-2/3 gap-8 md:gap-16 m-8">
            {/* <div id="about" className="w-2/3 flex flex-col md:flex-row m-16 gap-5 justify-items-center"> */}
                <div className="col-span-1 m-12 md:m-0">
                    <img
                    src={profile}
                    alt="PFP Pic"
                    className="rounded-full"

                    />

                    <h1 className="text-xl">
                    <a href="mailto:melbeshb.org@gmail.com">melbeshb.org@gmail.com</a>
                    <br></br>
                    <a href="https://www.linkedin.com/in/melbeshb">linkedin/melbeshb</a>
                    <br></br>
                    <a href="https://github.com/maryam-elbeshbishy">github/maryam-elbeshbishy</a></h1>

                </div>

                <div className="grid text-left col-span-1 md:col-span-2 gap-2">
                    <h1 className="font-bold text-6xl">Allo!</h1>
                    <p className="text-md md:text-xl">Name's Maryam, currently a computer science student wanting to create sick experiences for others.
                    Long term goal is to turn my passion for art and tech into something that makes people happy =D. Apart from CS I am an avid Cuber and a VR enthusiast. Check out the Channel!
                    <br></br>PS. All the images on here were drawn by me :D. <br></br>
                    Feel free to message me on LinkedIn, DMs are always open.</p>
                    
                    <a href={resume} className="hover:underline font-bold text-xl md:text-3xl">Check out the resume here!</a>
        
                    
                </div>
           </div>
        </div>
        
    )
}