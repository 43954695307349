
import React from 'react';
import Typewriter from "typewriter-effect";
export default function Landing(){
    const email_path = '/images/mail.png';
    const gitub_path = '/images/github.png';
    const linkedin_path = '/images/linkedin.png';
    const youtube_path = '/images/youtube.png';
    return(

    <div>
        <div className='grid gap-4 p-4 md:p-0 place-content-center justify-items-center h-[95vh]'>
            <h1 className='text-6xl font-bold'>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .typeString("Maryam Elbeshbishy")
                            .start();
                    }}
                />
            </h1>
            <h2 className='text-2xl'>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .typeString("Toronto Based")
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString("Computer Science Student")
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString("Artist")
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString("VR Enthusiast")
                            .pauseFor(1000)
                            .deleteAll()
                            .typeString("Toronto Based")
                            .start();
                    }}
                />       
            </h2>
            <h1>Scroll down to learn more :)</h1>
            
            <div className="flex flex-row justify-between pt-6 gap-6">
                <a href="mailto:melbeshb.org@gmail.com">
                    <img 
                        src={email_path}
                        alt="email icon"
                    /> 
                </a>

                <a href="https://github.com/maryam-elbeshbishy">
                    <img 
                        src={gitub_path}
                        alt="github icon"
                        
                    /> 
                </a>
                <a href="https://www.linkedin.com/in/melbeshb">
                    <img 
                        src={linkedin_path}
                        alt="linkedin icon"
                    /> 
                </a>
                
                <a href="https://www.youtube.com/@marilambb">
                    <img 
                        src={youtube_path}
                        alt="youtube icon"
                    /> 
                </a>
                
            </div>
        </div>
        
    </div>
        
    )
}