
export default function NavBar(){
    return(
        
    <div>
        <nav className="bg-gray-800 py-4 text-white">
        <div className="container mx-auto flex items-center justify-center">
            <ul className="flex text-sm md:text-2xl cursor-pointer space-x-6 gap-0 md:gap-6">
                <li>
                    <a href="#home">Home</a>
                </li>
                <li>
                    <a href="#about">About</a>
                </li>
                <li>
                <a href="#projects">SideQuests</a>
                </li>
                <li>
                <a href="#experience">MainQuests</a>
                </li>
                
                {/* <li>
                    <Link to="/art">Art</Link>
                </li> */}

            </ul>
        </div>
        </nav>
    </div>
        
    )
}